<template>
  Matchup:
  <select>
    <option>
      Minh Thai vs. Abraham Lincoln (bracket 2)
    </option>
  </select>
  <button class="refresh-matchups">🔄 Refresh matchups</button>
  <span></span>
  <button class="reset-current-matchup">❌ Reset current matchup</button>
</template>
