<template>
  <div class="result-input" loading>
    <button class="get-result">Get result</button>
    <loading-indicator></loading-indicator>
    <timed-result></timed-result>
    <span></span>
    <button class="plus-2">+2</button>
    <button class="minus-2">-2</button>
    <button class="DNF">DNF</button>
  </div>
  <button class="finish-attempt">Finish attempt</button>
</template>
