<template>
  <div class="info">
    <div class="split scrambler"><span>Scrambler:&nbsp;<span
          class="scrambler-name">(unassigned)</span></span><span><button class="set-scrambler"
          data-original-text="Set scrambler">Set
          scrambler</button>&nbsp;<button class="additional-actions-button"
          title="Additional actions…">…</button></span></div>
    <div class="competitor"></div>
    <div class="split">
      <div class="event"></div>
      <div class="matchup"></div>
      <div class="scramble-set"></div>
    </div>
    <div class="split">
      <div class="round"></div>
      <div class="attempt"></div>
    </div>
  </div>
  <twisty-alg-viewer></twisty-alg-viewer>
  <div class="signed">✍️ Signed</div>
  <twisty-player background="none" control-panel="none" visualization="2D"></twisty-player>
  <multi-blind-grid-display hidden></multi-blind-grid-display>
  <div class="multi" hidden>
    <button class="previous" disabled>⬅️ Previous</button>
    <div>Puzzle <span class="current-sub-scramble-num">1</span>/<span class="total-sub-scramble-num">?</span></div>
    <button class="next">Next ➡️</button>
    <button class="all">Show All</button>
  </div>
  <dialog class="additional-actions">
    <button class="close">Close</button>
    <br>
    <br>
    <h1>Additional&nbsp;actions</h1>
    <h2>Scrambler:&nbsp;<span class="scrambler-name">(unassigned)</span></h2>
    <hr>
    <br>
    <span><button class="clear-scramble">Clear scramble</button></span>
  </dialog>
</template>
