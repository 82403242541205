competitor-scramble-display {
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  border: 1px solid;
}

competitor-scramble-display .info {
  display: grid;
  border-bottom: 1px solid;
}

competitor-scramble-display,
competitor-scramble-display .info {
  border-color: var(--border-color);
}

competitor-scramble-display .info > div:nth-child(odd) {
  background: color-mix(
    in oklab,
    var(--scramble-table-main-color) 5%,
    transparent
  );
}

competitor-scramble-display .info > div {
  padding: 0.5em;
}

competitor-scramble-display .info .split {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 1em;
}

competitor-scramble-display .info .scrambler,
competitor-scramble-display twisty-alg-viewer {
  border-bottom: 1px solid;
}

competitor-scramble-display .info .competitor {
  font-size: 1.5em;
  text-align: center;
}

competitor-scramble-display twisty-alg-viewer {
  font-size: 1.75em;
  padding: 0.75em;
  box-sizing: border-box;
  display: block;
}

competitor-scramble-display .signed {
  text-align: center;
  font-size: 1.75em;
  padding: 0.75em;
  box-sizing: border-box;
}

competitor-scramble-display:not(.scramble-signed) .signed {
  display: none;
}

competitor-scramble-display.scramble-signed {
  grid-template-rows: auto auto auto 1fr;
}

competitor-scramble-display > twisty-player {
  width: 100%;
  height: 100%;
  min-height: 200px;
}

competitor-scramble-display.scramble-signed
  :is(twisty-alg-viewer, twisty-player) {
  opacity: 0.15;
}

competitor-scramble-display .multi {
  display: grid;
  grid-auto-flow: column;
  padding: 1em;
  gap: 1em;
}

competitor-scramble-display multi-blind-grid-display {
  contain: size;
  overflow: hidden;
}

competitor-scramble-display:not(.scramble-signed) .signed {
  display: none;
}

.multi > div {
  text-align: center;
}

competitor-scramble-display:not(.show-multi) .multi {
  display: none;
}

competitor-scramble-display.show-multi-grid > twisty-player {
  display: none;
}

scramble-table .additional-actions {
  border: 1px solid var(--scramble-table-border-color);
  padding: 2em;
  line-height: 1.5em;
  color: var(--scramble-table-main-color);
  backdrop-filter: blur(0.25em);
  background: color-mix(
    in oklab,
    var(--scramble-table-background) 90%,
    transparent
  );
}

scramble-table .additional-actions h1 {
  margin-top: 0;
}

scramble-table .additional-actions .close {
  float: right;
  position: relative;
}

scramble-table dialog::backdrop {
  background-color: #444;
  opacity: 0.75;
  z-index: 1;
}

competitor-scramble-display.matchup .scramble-set {
  display: none;
}
competitor-scramble-display:not(.matchup) .matchup {
  display: none;
}
