scramble-table {
  background: var(--scramble-table-background);
  color: var(--scramble-table-main-color);

  box-sizing: border-box;
  margin: 0;
  display: grid;
  padding: 2em;
  gap: 1em;

  --scramble-table-matchup-selection-size: ;
  grid-template-rows: auto var(--scramble-table-matchup-selection-size) 1fr;
  font-family: Ubuntu, Tahoma, sans-serif;
}

scramble-table:has(scramble-table-matchup-selection) {
  --scramble-table-matchup-selection-size: auto;
}
scramble-table > header {
  font-size: 2em;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
}

scramble-table > header button {
  font-size: 1em;
}

scramble-table > header button.settings-button {
  font-size: 1em;
  background: none;
  color: var(--scramble-table-main-color);
  border: none;
}

scramble-table > header button.settings-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

scramble-table scramble-table-contents {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 2em;
}

scramble-table .scramble-table-settings {
  border: 1px solid var(--scramble-table-border-color);
  padding: 2em;
  line-height: 1.5em;
  color: var(--scramble-table-main-color);
  backdrop-filter: blur(0.25em);
  background: color-mix(
    in oklab,
    var(--scramble-table-background) 90%,
    transparent
  );
}

scramble-table .scramble-table-settings h1 {
  margin-top: 0;
}

scramble-table .scramble-table-settings .close {
  float: right;
  position: relative;
}

scramble-table dialog::backdrop {
  background-color: #444;
  opacity: 0.75;
  z-index: 1;
}
